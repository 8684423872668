<template>
    <table class="table border service-table">
        <thead>
            <tr>
                <th v-if="editingOptimizedShippingTable"><mercur-checkbox data-test="OptimizedShippingTableSelectAll" v-model="selectAll" color="purple"></mercur-checkbox></th>
                <template v-if="selectedRows.length > 0">
                    <th>
                        <mercur-tooltip>
                            <template slot="label">Delete selected rows</template>
                            <mercur-button data-test="OptimizedShippingTableDeleteSelected" @click="deleteSelectedRows" class="btn btn-icon">
                                <i class="fas fa-trash"></i>
                            </mercur-button>
                        </mercur-tooltip>
                    </th>
                </template>
                <template v-else>
                    <th>Real shipping <br><span>Cost price range</span></th>
                    <th>Optimized shipping <br><span>Cost price</span></th>
                    <th v-if="editingOptimizedShippingTable" />
                </template>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in values" :key="index" >
                <td :data-test="'OptimizedShippingTableSelectRow-'+index" v-if="editingOptimizedShippingTable">
                    <mercur-checkbox color="purple" v-model="selectedRows" :value="item.temporaryId"></mercur-checkbox>
                </td>
                <td :data-test="'OptimizedShippingTableItemTo-'+index">
                    <div class="d-flex">
                        <label class="py-2 pr-2" v-if="editingOptimizedShippingTable">To</label>
                        <template v-if="editingOptimizedShippingTable">
                            <mercur-input v-model="item.to" type="text">
                                <template slot="note">
                                    <span class="error" v-if="$v.values.$dirty && !$v.values.$each[index].to.required">Required</span>
                                    <span class="error" v-if="$v.values.$dirty && !$v.values.$each[index].to.numeric">Needs to be numeric</span>
                                    <span class="error" v-if="$v.values.$dirty && !$v.values.$each[index].to.unique">Has to be unique value!</span>
                                </template>
                            </mercur-input>
                        </template>
                        <template v-else>{{ item.to }}</template>
                    </div>
                </td>
                <td :data-test="'OptimizedShippingTablePrice-'+index">
                    <template v-if="editingOptimizedShippingTable">
                        <mercur-input v-model="item.price" type="text">
                            <template slot="note">
                                <span class="error" v-if="$v.values.$dirty && !$v.values.$each[index].price.required">Required</span>
                                <span class="error" v-if="$v.values.$dirty && !$v.values.$each[index].price.numeric">Needs to be numeric</span>
                            </template>
                        </mercur-input>
                    </template>
                    <template v-else>{{ item.price }}</template>
                </td>
                <td :data-test="'OptimizedShippingTableRowMenu-'+index" v-if="editingOptimizedShippingTable">
                    <mercur-menu clickOutside>
                        <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                        <div slot="dropdown">
                            <mercur-item :data-test="'RowMenuOptionInsertAbove-'+index" @click.native="insertRowAbove(index)">Insert row above</mercur-item>
                            <mercur-item :data-test="'RowMenuOptionInsertBelow-'+index" @click.native="insertRowBelow(index)">Insert row below</mercur-item>
                            <mercur-item :data-test="'RowMenuOptionDelete-'+index" class="border-top" @click.native="deleteRow(item.temporaryId)">Delete row</mercur-item>
                        </div>
                    </mercur-menu>
                </td>
            </tr>
            <tr v-if="editingOptimizedShippingTable">
                <td @click="insertRowBelow(values.length - 1)" class="u-text-center" colspan="4">
                    <mercur-button data-test="optimizedShippingTableAddRowButton" class="btn btn-outline-blue font-weight-bold"><i class="fas fa-plus-square"></i> Add row</mercur-button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { numeric, required } from 'vuelidate/lib/validators'

export default {
    name: 'OptimizedShippingTable',
    props: {
        values: {
            required: true,
            default: [],
        },
        editingOptimizedShippingTable: {},
    },
    data () {
        return {
            selectedRows: [],
            selectAll: false,
        }
    },
    validations: {
        values: {
            $each: {
                to: {
                    numeric,
                    required,
                    unique: function (value, vm) {
                        return this.checkIfToIsUnique(vm)
                    },
                },
                price: {
                    numeric,
                    required,
                },
            },
        },
    },
    watch: {
        selectAll (value) {
            if (value) {
                this.$set(this, 'selectedRows', this.values.map(e => e.temporaryId))
            } else {
                this.$set(this, 'selectedRows', [])
            }
        },
        selectedRows (value) {
            if (!value.length) {
                this.$set(this, 'selectAll', false)
                return
            }

            if (value.length === this.values.length) {
                this.$set(this, 'selectAll', true)
            }
        },
    },
    methods: {
        deleteSelectedRows () {
            this.selectedRows.forEach((temporaryId) => {
                this.deleteRow(temporaryId)
            })
            this.$set(this, 'selectedRows', [])
        },
        insertRowAbove (index) {
            this.values.splice(index, 0, {
                to: '',
                price: '',
                temporaryId: this.$uuid.v4(),
            })
        },
        insertRowBelow (index) {
            this.values.splice(index + 1, 0, {
                to: '',
                price: '',
                temporaryId: this.$uuid.v4(),
            })
        },
        deleteRow (temporaryId) {
            const index = this.values.findIndex(e => e.temporaryId === temporaryId)
            this.values.splice(index, 1)
        },
        checkIfToIsUnique (vm) {
            if (!this.values || !Array.isArray(this.values)) {
                return false
            }

            const tos = this.values.map(e => e.to)
            const numOfTos = tos.filter(e => e === vm.to)
            return !(numOfTos && numOfTos.length > 1)
        },
    },
}
</script>
<style lang="scss">
    .service-table {
        border-collapse:separate;
        border-spacing: 0;
        border-radius:6px;
        -moz-border-radius:6px;
    thead th {
        border: none;
        vertical-align: middle;
    }
    tr:hover {
        background-color: #f1f1f1;
        cursor: pointer;
    }
    td {
        vertical-align: middle;
    }
}
</style>

<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true">
            Optimized Shipping
            <template slot="actions">
                <mercur-button data-test="optimizeShippingAddNewButton" @click="triggerAdd" class="btn btn-raised btn-primary">
                    <span>New optimized shipping table</span>
                </mercur-button>
            </template>
        </grid-header>
        <mercur-card class="bg-primary" style="min-height: 92px;">
            <pretty-select
                data-test="optimizeShippingSelector"
                v-if="serviceLevelMappings && optimizedShippings.length && !loading"
                v-model="selectedOptimizedShipping"
                :options="optimizedShippings"
                label="optimizedShippingName"
                placeholder="Select existing optimized shipping table"
                color="white"
            ></pretty-select>
            <mercur-spinner
                v-else-if="loading"
            />
        </mercur-card>
        <div v-if="Object.keys(selectedOptimizedShipping).length" class="mx-4">
            <div class="row">
                <div class="col">
                    <p class="fs-16 mt-20">{{ selectedOptimizedShipping.optimizedShippingDescription }}</p>
                    <p>
                        <mercur-button data-test="optimizeShippingEditButton" class="btn btn-outline-blue" @click="triggerEdit" :disabled="editingOptimizedShippingTable || loading"><i class="fas fa-pen"></i>Edit details</mercur-button>
                        <mercur-button data-test="optimizeShippingDuplicateButton" class="btn btn-outline-blue" @click="triggerDuplicate" :disabled="editingOptimizedShippingTable || loading"><i class="fas fa-clone"></i>Duplicate table</mercur-button>
                        <mercur-button data-test="optimizeShippingDeleteButton" class="btn btn-danger btn-raised m-0 u-float-right" @click="triggerDelete" :disabled="editingOptimizedShippingTable || loading">Delete optimized shipping</mercur-button>
                    </p>
                    <hr>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <mapping-detail :service-level-mapping="ownMapping"></mapping-detail>
                </div>
                <div class="col flex justify-content-end align-items-center">
                    <mercur-button class="btn btn-outline-blue mr-1" v-if="editingOptimizedShippingTable" @click="triggerUndo" :disabled="loading"><i class="fas fa-undo"></i> Revert changes</mercur-button>
                    <mercur-button data-test="optimizeShippingEditTableButton" class="btn btn-raised mr-1" @click="triggerEditTable" :disabled="loading">{{editingOptimizedShippingTable ? 'Cancel' : 'Edit table'}}</mercur-button>
                    <mercur-button class="btn btn-raised btn-success" @click="updateOptimizedShippingTable" :disabled="!editingOptimizedShippingTable || loading">Save changes</mercur-button>
                </div>
            </div>
            <div class="row">
                <div class="col-6" v-for="(values, key) in selectedOptimizedShipping.optimizedShippingTable" :key="key">
                    <h4>{{ key }} Service Levels</h4>
                    <optimized-shipping-table data-test="optimizeShippingTables" :values.sync="values" v-bind="$data" ref="tables"></optimized-shipping-table>
                </div>
            </div>
        </div>

        <mercur-dialog data-test="optimizeShippingDeleteModal" :is-open.sync="isDelete">
            <p>Are you sure you want to remove this optimized shipping?</p>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="optimizeShippingDeleteModalConfirmButton" class="btn btn-raised btn-primary" :disabled="loading" @click="remove">Delete</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog data-test="optimizeShippingAddNewModal" :is-open.sync="isOpen">
            <template v-if="isDuplicate">
                <h2 class="font-weight-normal mt-0">Duplicate optimized shipping table</h2>
                <p>You can change name and description, but optimized shipping table will be cloned</p>
            </template>
            <h3 class="font-weight-normal mt-0" v-else>{{ draft ? 'Edit optimized shipping' : 'Add new optimized shipping'}}</h3>
            <mercur-input data-test="optimizeShippingModalNameInput" v-model.trim="form.optimizedShippingName" :class="{'form-invalid': $v.form.optimizedShippingName.$error}">
                Optimized shipping table name
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.optimizedShippingName.required">Optimized shipping name is required</span>
                    <span class="form-error" v-else-if="!$v.form.optimizedShippingName.unique">Optimized shipping name needs to be unique</span>
                </template>
            </mercur-input>
            <mercur-input data-test="optimizeShippingModalDescriptionInput" v-model.trim="form.optimizedShippingDescription">
                Optimized shipping description
            </mercur-input>
            <pretty-select
                data-test="optimizeShippingSelectorModal"
                v-if="serviceLevelMappings"
                v-model="form.serviceLevelMappingId"
                :options="serviceLevelMappings"
                :reduce="mapping => mapping.serviceLevelMappingId"
                label="serviceLevelMappingName"
                placeholder="Select service level mapping"
            ></pretty-select>
            <span class="error pretty-select-error position-relative" v-if="$v.form.serviceLevelMappingId.$error">
                    Service level mapping is required
            </span>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="optimizeShippingModalDuplicateButton" v-if="isDuplicate" @click="submit" class="btn btn-raised btn-primary" :disabled="loading">Duplicate</mercur-button>
                <mercur-button data-test="optimizeShippingModalSaveButton" v-else class="btn btn-raised btn-primary" @click="submit" :disabled="loading">
                    {{ draft ? 'Update optimized shipping' : 'Add new optimized shipping'}}
                </mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PrettySelect from '../../components/utils/PrettySelect'
import OptimizedShippingTable from '../../components/pricing/OptimizedShippingTable'
import MappingDetail from '../../components/pricing/MappingDetail'
import { required } from 'vuelidate/lib/validators'
import { reverseStripProperties } from '@/components/utils/Utils'

const defaultForm = {
    optimizedShippingName: '',
    optimizedShippingDescription: '',
    optimizedShippingTable: {},
}

export default {
    name: 'OptimizedShipping',
    components: { GridHeader, PrettySelect, OptimizedShippingTable, MappingDetail },
    data () {
        return {
            serviceLevelMappings: null,
            draft: null,
            isOpen: false,
            isEdit: false,
            isDuplicate: true,
            form: defaultForm,
            isDelete: false,
            loading: false,
            optimizedShippings: [],
            selectedOptimizedShipping: {},
            editingOptimizedShippingTable: false,
            selectedOptimizedShippingDraft: null,
        }
    },
    watch: {
        selectedOptimizedShipping (value) {
            this.$router.push({
                name: 'OptimizedShipping',
                params: {
                    optimizedShippingId: value.optimizedShippingId,
                },
            })

            if (!this.serviceLevelMappings) {
                return
            }
            const mapping = this.serviceLevelMappings.find(e => e.serviceLevelMappingId === value.serviceLevelMappingId)

            if (!Array.isArray(mapping.serviceLevelLabels)) {
                return
            }
            mapping.serviceLevelLabels.forEach((label) => {
                if (!Object.keys(value.optimizedShippingTable).includes(label)) {
                    this.selectedOptimizedShipping.optimizedShippingTable[label] = []
                }
            })
        },
    },
    validations: {
        form: {
            optimizedShippingName: {
                required,
                unique: function (value, vm) {
                    return this.checkIfUnique(vm)
                },
            },
            serviceLevelMappingId: {
                required,
            },
        },
    },
    computed: {
        ownMapping () {
            if (!this.serviceLevelMappings) {
                return null
            }

            return this.serviceLevelMappings.find(e => e.serviceLevelMappingId === this.selectedOptimizedShipping.serviceLevelMappingId)
        },
    },
    methods: {
        listServiceLevelMappings () {
            const url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_MAPPING.LIST
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                if (data.data.items && data.data.items.length) {
                    this.$set(this, 'serviceLevelMappings', data.data.items)
                }
            })
        },
        triggerAdd () {
            this.draft = null
            this.isOpen = true
            this.isDuplicate = false
        },
        triggerEdit () {
            this.draft = JSON.parse(JSON.stringify(this.selectedOptimizedShipping))
            this.form = this.draft
            this.isOpen = true
            this.isEdit = true
            this.isDuplicate = false
        },
        triggerClose () {
            this.draft = null
            this.isOpen = false
            this.isDuplicate = false
            this.isEdit = false
            this.form = defaultForm
            this.isDelete = false
        },
        triggerDuplicate () {
            this.isOpen = true
            this.isDuplicate = true
            this.form = JSON.parse(JSON.stringify(this.selectedOptimizedShipping))
        },
        triggerDelete () {
            this.isDelete = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.OPTIMIZED_SHIPPING.DELETE.replace('{optimizedShippingId}', this.selectedOptimizedShipping.optimizedShippingId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Optimized shipping was deleted',
                    type: 'success',
                })
                const index = this.optimizedShippings.findIndex(e => e.optimizedShippingId === data.data.optimizedShippingId)
                this.triggerClose()
                this.$delete(this.optimizedShippings, index)
                this.$set(this, 'selectedOptimizedShipping', {})
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit () {
            this.$v.$touch()
            if (this.$v.form.$invalid) {
                return
            }

            if (this.isEdit) {
                this.updateOptimizedShipping()
                return
            }

            const url = CONFIG.API.ROUTES.PRICING.OPTIMIZED_SHIPPING.CREATE
            let payload = this.form
            if (this.form.optimizedShippingId) {
                payload = reverseStripProperties(payload, ['optimizedShippingName', 'optimizedShippingDescription', 'optimizedShippingTable', 'serviceLevelMappingId'])
            }

            payload.serviceLevelMappingId = this.form.serviceLevelMappingId
            const selectedMapping = this.serviceLevelMappings.find(e => e.serviceLevelMappingId === this.form.serviceLevelMappingId)
            // PART TO ADD EMPTY LABELS ON CREATE
            selectedMapping.serviceLevelLabels.forEach((label) => {
                if (!payload.optimizedShippingTable[label]) {
                    payload.optimizedShippingTable[label] = {}
                }
            })

            this.loading = true
            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Optimized shipping table was added',
                    type: 'success',
                })
                this.$set(this, 'optimizedShippings', [
                    ...this.optimizedShippings,
                    data.data,
                ])
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        listOptimizedShippings () {
            const url = CONFIG.API.ROUTES.PRICING.OPTIMIZED_SHIPPING.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'optimizedShippings', data.data.items)
                if (this.$route.params.optimizedShippingId) {
                    this.$set(this, 'selectedOptimizedShipping', this.optimizedShippings.find(e => e.optimizedShippingId === this.$route.params.optimizedShippingId))
                }
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching data',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        async updateOptimizedShipping (data) {
            const url = CONFIG.API.ROUTES.PRICING.OPTIMIZED_SHIPPING.UPDATE
            this.loading = true
            const payload = data || this.form
            await this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Optimized shipping table was updated',
                    type: 'success',
                })
                const changedTableIndex = this.optimizedShippings.findIndex(e => e.optimizedShippingId === data.data.optimizedShippingId)
                const newArray = this.optimizedShippings
                newArray[changedTableIndex] = data.data
                this.$set(this, 'optimizedShippings', newArray)
                this.$set(this, 'selectedOptimizedShipping', data.data)
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        checkIfUnique (vm) {
            let shippings = JSON.parse(JSON.stringify(this.optimizedShippings))
            if (this.isEdit) {
                shippings.splice(shippings.findIndex(e => e.optimizedShippingId === this.selectedOptimizedShipping.optimizedShippingId), 1)
            }
            const previousNames = shippings.map(e => e.optimizedShippingName)
            return !previousNames.includes(vm.optimizedShippingName)
        },

        // TABLE METHODS
        updateOptimizedShippingTable () {
            let isValid = true
            this.$refs.tables.forEach((table) => {
                table.$v.$touch()
                if (table.$v.values.$invalid) {
                    isValid = false
                }
            })

            if (!isValid) {
                return
            }
            this.updateOptimizedShipping(this.selectedOptimizedShipping).then(() => {
                this.editingOptimizedShippingTable = false
                this.selectedOptimizedShippingDraft = null
            })
        },
        triggerEditTable () {
            if (this.editingOptimizedShippingTable) {
                this.editingOptimizedShippingTable = false
                this.triggerUndo()
                this.selectedOptimizedShippingDraft = null
                return
            }

            this.editingOptimizedShippingTable = true
            this.selectedOptimizedShippingDraft = JSON.parse(JSON.stringify(this.selectedOptimizedShipping.optimizedShippingTable))
            this.addTemporaryIdsToOptimizedShippingRules()
        },
        addTemporaryIdsToOptimizedShippingRules () {
            for (let key in this.selectedOptimizedShipping.optimizedShippingTable) {
                this.selectedOptimizedShipping.optimizedShippingTable[key] = this.selectedOptimizedShipping.optimizedShippingTable[key].map((e) => {
                    return { ...e, temporaryId: this.$uuid.v4() }
                })
            }
        },
        triggerUndo () {
            this.$set(this.selectedOptimizedShipping, 'optimizedShippingTable', JSON.parse(JSON.stringify(this.selectedOptimizedShippingDraft)))
        },
    },
    created () {
        this.listServiceLevelMappings()
        this.listOptimizedShippings()
    },
}
</script>

<template>
    <div data-test="mappingDetailWrapper">
        <mercur-button data-test="mappingDetailButtonDisplayDetail" class="btn btn-raised btn-success ml-0" @click="displayDetail">View mapping details</mercur-button>
        <mercur-dialog :is-open.sync="isDetailOpen">
            <div data-test="mappingDetailContent" v-if="serviceLevelMapping">
                <p>This optimized shipping is attached to <strong>{{ serviceLevelMapping.serviceLevelMappingName }}</strong></p>
                <p>And it has these labels <strong>{{ serviceLevelMapping.serviceLevelLabels.join(', ')}}</strong></p>
                <p>These labels have following service levels:</p>
                <ul data-test="mappingDetailContentServiceLevelMappingTable" class="list" v-for="(values, key) in serviceLevelMapping.serviceLevelMappingTable" :key="key">
                    <li class="list__label"><strong>{{ key }}:</strong></li>
                    <li v-for="value in values" :key="value">
                        - {{ displayServiceLevelName(value) }}
                    </li>
                </ul>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'
export default {
    name: 'MappingDetail',
    props: {
        serviceLevelMapping: {
            required: true,
        },
    },
    data () {
        return {
            serviceLevels: null,
            isDetailOpen: false,
        }
    },
    methods: {
        displayDetail () {
            this.isDetailOpen = true
        },
        displayServiceLevelName (id) {
            if (!this.serviceLevels) {
                return 'name not found'
            }

            const serviceLevel = this.serviceLevels.find(e => e.serviceLevelId === id)

            if (!serviceLevel) {
                return 'name not found'
            }

            return serviceLevel.serviceLevelName
        },
        listAllServiceLevels () {
            const url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.OVERVIEW_ALL
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'serviceLevels', data.data.items)
            })
        },
    },
    created () {
        this.listAllServiceLevels()
    },
}
</script>

<style lang="scss" scoped>
    .list {
        list-style: none;
        padding: 0;

        &__label {
            font-size: 1.1em;
        }
    }
</style>
